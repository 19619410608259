import React from 'react' 
import DialogLayout from './../../../layouts/DialogLayout'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

export default function AccountModal({ open, handleClose }) {
    const { t } = useTranslation();
    return (
        <div className={'account-dialog'}>
            <DialogLayout
                open={open}
                handleClose={handleClose}
                title={t('Checkout Modal Your Account')}
            >
                <div>
                    <Typography gutterBottom> 
                        {t('Checkout Modal Message')}
                    </Typography>
                    <Link
                        to={'/payment-form'}
                        state={{ option: 'Login' }}
                    >
                        <Button
                            variant='contained'
                            color="primary"
                            fullWidth
                        >
                            {t('Checkout Modal Button Login')}
                        </Button>
                    </Link>
                    <Link
                        to={'/payment-form'}
                        state={{ option: 'Register' }}
                    >
                        <Button
                            variant='contained'
                            color="secondary"
                            fullWidth
                        >
                            {t('Checkout Modal Button Register')}
                        </Button>
                    </Link> 
                    <Typography >
                        or
                    </Typography>

                    <Link
                        to={'/payment-form'}
                        state={{ option: 'Guest' }}
                    >
                        <Button
                            variant='outlined'
                            color="primary"
                            fullWidth
                        >
                            {t('Checkout Modal Button Guest')}
                        </Button>
                    </Link>
                </div>
            </DialogLayout>
        </div>
    )
}

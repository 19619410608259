import { Button } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
// import './CheckoutDetail.scss';
import IconCheckout1 from '../../images/icon-checkout-1.png'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccountModal from '../../components/modals/AccountModal';
import { getDateDetailFromDB, getTimeDetailFromDB } from '../../utils/datetime';
import * as Storage from '../../utils/storage';
import { navigate } from 'gatsby';

export default function CheckoutDetail({ booking }) {
    const { t } = useTranslation();
    const [openAccountModal, setOpenAccountModal] = useState(false)
    const { language } = useContext(I18nextContext);

    const handleOpen = () => {
        if (Storage.getItem(Storage.TOKEN_USER)) {
            navigate(language === 'en' ? `/${language}/payment-form` : `/payment-form`, { state: { option: 'Login' } });
        } else {
            setOpenAccountModal(true);
        }
    }
    const handleClose = () => setOpenAccountModal(false)

    return (
        <>
            <h1>{t('Checkout Your Stay')}</h1>
            <p><img src={IconCheckout1} /><span>{`${booking?.base?.base_iata_code} ${booking?.base?.base_name}`}</span></p>
            <br></br>
            <div className='chekout-date-limits'>
                <div>
                    <p>{t('Checkout From')}</p>
                    <h2>{getDateDetailFromDB(booking?.book_init_datetime,language)}</h2>
                    <p>{getTimeDetailFromDB(booking?.book_init_datetime)}</p>
                </div>
                <div className='chekout-duration'>
                    <AddCircleOutlineIcon />
                    <p>
                        {`${booking?.book_duration_hours} ${t('Hours')}`}
                        {(booking?.book_duration_minutes !== 0) ? ` ${booking?.book_duration_minutes} ${t('Minutes')}` : ''}
                    </p>
                </div>
                <div>
                    <p>{t('Checkout To')}</p>
                    <h2>{getDateDetailFromDB(booking?.book_end_datetime,language)}</h2>
                    <p>{getTimeDetailFromDB(booking?.book_end_datetime)}</p>
                </div>
            </div>
            {/* <br></br>
            <div className='chekout-cabin'>
                <div className='chekout-cabin-number'>
                    <p>Cabin</p>
                    <h2>1003</h2>
                </div>
                <div className='chekout-cabin-message'>
                    <p>
                        When you arrive, look for this cabin number. We will be ready for you.
                    </p>
                </div>
            </div> */}
            <br></br>
            <div className='chekout-end'>
                <div><p>Total <h2>{`$${booking?.book_total}`}</h2></p></div>
                <Button
                    variant='contained'
                    color="primary"
                    onClick={handleOpen}
                >
                    {t('Next')}
                </Button>
            </div>
            <AccountModal open={openAccountModal} handleClose={handleClose} />
        </>
    )
}

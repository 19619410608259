import React from 'react' 
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IconCheckout2 from '../../images/icon-checkout-2.png'
import IconCheckout3 from '../../images/icon-checkout-3.png'
import IconCheckout4 from '../../images/icon-checkout-4.png'
import IconCheckout5 from '../../images/icon-checkout-5.png'
import IconCheckout6 from '../../images/icon-checkout-6.png'
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function CheckoutRules({ booking, cabinTypeId }) {
    const { t } = useTranslation();
    return (
        <>
            <ErrorOutlineIcon/>
            <h4>{t('Checkout Basic Rules')}</h4> 
            <p><img src={IconCheckout2} alt='regla 1'/><span>{t(`Checkout Basic${cabinTypeId != 2 ? '' : '2'} Rules P1`)}</span></p>
            <p><img src={IconCheckout3} alt='regla 2'/><span>{t('Checkout Basic Rules P2')}</span></p>
            <p><img src={IconCheckout4} alt='regla 3'/><span>{t('Checkout Basic Rules P3')}</span></p>
            <p><img src={IconCheckout5} alt='regla 4'/><span>{t('Checkout Basic Rules P4')}</span></p>
            <p><img src={IconCheckout6} alt='regla 5'/><span>{t('Checkout Basic Rules P5')}</span></p>
            <p><img src={IconCheckout6} alt='regla 6'/><span>{t('Checkout Basic Rules P6')}</span></p>
        </>
    )
}

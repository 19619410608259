import React, { useContext } from 'react' 
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Grid, Container } from '@material-ui/core';
import CheckoutDetail from './../components/CheckoutDetail'
import CheckoutRules from './../components/CheckoutRules'
import AvatarSearch from './../images/avatar-search.png'
import { DataContext } from '../context/DataContext';

export default function CheckOut() {
    const { t } = useTranslation();
    const {booking, cabinTypeId } = useContext(DataContext); 
 
    if (!booking?.base) {
        if (typeof window !== 'undefined') {
            navigate('/reserve'); 
          } 
        return <></>
    };

    return (
        <MainLayout withMargin={false}>
            <Seo title={t('Check Out')} />
            <Grid container className='check-out'>
                <Grid  
                container item xs={12} md={6} className={'check-out-item'}>
                    <Container
                    data-sal="slide-right"
                    data-sal-duration="2000"
                    >
                        <Grid container className={'check-out-container'} alignItems={'center'}>
                            <Grid container item xs={12} justify={'center'} direction={'column'} className='check-out-detail'>
                                <CheckoutDetail booking={booking}/>
                            </Grid> 
                        </Grid>
                    </Container>
                </Grid>
                <Grid
                container item xs={12} md={6} className={'check-out-item'} alignItems={'center'}>
                    <Container
                    data-sal="slide-left"
                    data-sal-duration="2000">
                        <Grid container className={'check-out-container'} alignItems={'center'}>
                            <Grid container item xs={12} md={8} justify={'center'} direction={'column'}>
                                <CheckoutRules booking={booking} cabinTypeId={cabinTypeId}/>
                            </Grid>
                            <Grid container item xs={12} md={4} alignItems={'flex-end'} justify='flex-end'>
                                <img src={AvatarSearch} alt='buscar' />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </MainLayout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const TOKEN_USER='token'; 
export const EMAIL_AUTH='USERNAME_AUTH';
export const FULLNAMES_AUTH='FULLNAMES_AUTH';
export const COUNTRY_AUTH='COUNTRY_AUTH';

export const saveItem=(keyName, keyValue)=>{
    //console.log(keyName, keyValue);
    localStorage.setItem(keyName, keyValue);
};

export const getItem=(keyName)=>{
    return localStorage.getItem(keyName); 
};

export const clearAll=()=>{
    localStorage.clear(); 
}